<script lang="ts" setup>
import Player, { type Options } from "@vimeo/player";

const props = withDefaults(defineProps<{
  playerHeight?: number;
  playerWidth?: number;
  playerClass?: string;
  options?: Options;
  videoId?: number;
  videoUrl?: string;
  loop?: boolean;
  autoplay?: boolean;
  controls?: boolean;
  eventsToEmit?: string[];
}>(), {
  playerHeight: 320,
  playerWidth: 640,
  options: () => ({}),
  loop: false,
  autoplay: false,
  controls: true,
  eventsToEmit: () => [],
});

const emit = defineEmits([
  "play",
  "playing",
  "pause",
  "ended",
  "timeupdate",
  "progress",
  "seeking",
  "seeked",
  "texttrackchange",
  "chapterchange",
  "cuechange",
  "cuepoint",
  "volumechange",
  "playbackratechange",
  "bufferstart",
  "bufferend",
  "ready",
  "error",
  "loaded",
  "durationchange",
  "fullscreenchange",
  "qualitychange",
  "camerachange",
  "resize",
]);

const playerModel = defineModel<Player>();

const elementRef = ref<HTMLIFrameElement>();

const mergeOptions = ({ id, url }: { id?: number; url?: string }) => {
  const opts: Options = {
    width: props.playerWidth,
    height: props.playerHeight,
    loop: props.loop,
    autoplay: props.autoplay,
    controls: props.controls,
  };

  if (unref(url)) {
    opts.url = unref(url);
  }

  if (unref(id)) {
    opts.id = unref(id);
  }

  return Object.assign(opts, props.options);
};

watch(elementRef, () => {
  if (elementRef.value && !playerModel.value) {
    const player = new Player(elementRef.value, mergeOptions({ id: props.videoId, url: props.videoUrl }));

    player.ready().then(() => {
      if (props.playerClass) {
        elementRef.value?.querySelector("iframe")?.classList.add(...props.playerClass.split(" "));
      }

      emit("ready", player);

      playerModel.value = player;
    }).catch((error: unknown) => {
      emit("error", error, player);
    });

    props.eventsToEmit.forEach((event) => {
      player.on(event, (data) => {
        emit(event as keyof typeof emit, data, player);
      });
    });
  }
});

onBeforeUnmount(() => {
  playerModel.value?.unload();

  playerModel.value = undefined;
});

watch(toRef(props, "videoId"), (id) => playerModel.value?.loadVideo(mergeOptions({ id })));
watch(toRef(props, "videoUrl"), (url) => playerModel.value?.loadVideo(mergeOptions({ url })));
watch(toRef(props, "controls"), () => playerModel.value?.loadVideo(mergeOptions({ url: props.videoUrl, id: props.videoId })));
</script>

<template>
  <div ref="elementRef" />
</template>
